body{
  font-family: 'Open Sans', sans-serif;
  color:#0d2544;
  overflow-x: hidden;
  font-weight: 400;
  line-height: 1.6em;
  text-align:justify;
}
.headercall{
  text-decoration: none;
  color:white;
  font-weight: 600;
}
.headercall:hover{
  
  color: #4278f8!important;
}
.product-prof{
  width:100%;
}
ul{
  list-style: none;
}
ul li h5{
font-weight: 900;
}
.logo-area{
  width:200px;
}
#errorpage{
  text-align: center;
}

.fa-bomb{
  font-size: 5em;
}

.zoom:hover{
  transition: transform .2s; 
  background-color: #1b8cc8;
  cursor: pointer;
}
#brand-slogan{
  text-transform: uppercase;
  font-weight: 900;
}
.copyright
{
  font-size: 0.8em;
}
.bg{
background-color: #0f032a;
color:white;
padding: 0.5em;
}
.navbar-collapse {
  flex-grow: unset;
}
.navbar {
  /* background-color: black!important; */
   background-color: rgb(255, 254, 254)!important;
   
}
a{
  color:#8F8F8F !important;
}
.nav-link{
  font-size: 1em;
  color:#0f032a!important;
  padding: 0 0;
}

.dropdown-item{
  font-size: 1em;
  color:#0f032a!important;
}

.navbar-toggler{
 border: none!important;
 border: #0f032a;
 box-shadow: none;

}
.navbar-toggler:hover{
  border: none!important;
  box-shadow: none;
 }
 .navbar-toggler:focus{
   box-shadow: none;
 }
.collapsed{
  border: none!important;
  box-shadow: none;

 }
 #logo{
   width: 100%;
 }
 .carousel-item img{
   height: 100%!important;
 }
 #fav{
   text-align: right;
   padding:0.2em;
 }
 #home_tab h1{
   
   font-size: 1.8em;
   padding-top:1.5em;
   padding-bottom:1.5em;
 }
 .col{
   padding: 1em;
   
 }
 
 h1{
   font-size: 2em;
   text-align: center;
 }
 .card{
   background-color: #5a3c8b;
   color:white;
   height: 100%;
   text-align:justify;
  
 }
 .card p{
   font-size: 1em;
 }
 #footer{
   text-align: center;
   background-color: #376d96;
   color: white;
   padding-top: 3em;
 }
 .content{
   height: 90%px;
   padding-top: 3em;
   padding-bottom: 3em;
   

 }
 .title{
   text-align: left;
   font-size: 1em;
   background-color: #5a3c8b;
   color: white;
   padding: 1em;
 }
 .pic{
   width: 100%;
 }
 td{
  
   background-color: #5a3c8b!important;
   color:rgb(207, 170, 241);
   
 }
 td:hover{
 
  background-color: #3970c2!important;
  cursor: pointer;
  color: rgb(245, 214, 214);
}
.cntd {
  text-decoration: none;
  padding-top:0.5em;
	color: #f35626;
	background-image: -webkit-linear-gradient(92deg, #eb1854, #c10d66);
	background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: hue 5s infinite linear;
}

@keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}



.contact-form .form-control {
  background: transparent;
 
  color: #444;
}

.contact-form .form-control:focus {
  border-color: #4278f8;
}

.contact-form .contact-icon {
  left: 34px;
  position: absolute;
  top: 16px;
  color: #444;
}

.contact-form textarea {
  height: 268px;
  width: 100%;
  background: transparent;

  padding: 18px 18px;
  color: #444;
  margin-bottom: 35px;
  resize: vertical;
}

.contact-form textarea:focus {
  border-color: #EB586F;
}

.contact-form .form-group {
  margin-bottom: 0;
}

.contact-info-title {
  color: #444;
  font-size: 24px;
  margin-bottom: 25px;
}

.contact-info a {
  display: block;
  font-size: 14px;
  color: #444;
  margin-bottom: 10px;
  position: relative;
  font-weight: 400;
}

.contact-info a i {
  margin-right: 10px;
}

.contact-info address {
  position: relative;
  margin-bottom: 15px;
}

address {
  font-size: 14px;
  color: #444;
  line-height: 1.8;
}

address i {
  margin-right: 10px;
}

.tel-info {
  margin-bottom: 15px;
}

.social-links li {
  display: inline-block;
}

.social-links li a {
  color: #444;
  font-size: 18px;
  padding-left: 0;
  margin: 10px 20px 0 0;
}

.form-control {
  color: #444;
  background: #f7f7f7;
  font-weight: 400;
  letter-spacing: 0px;
  border: 1px solid rgb(47, 6, 100);
  border-radius: 0;
  margin-bottom: 30px;
  
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-control:focus {
  border-color: #EB586F;
}

.form-group input {
  font: normal normal normal 14px/1 FontAwesome, "Rubik", sans-serif;
}

.input-group input {
  font: normal normal normal 14px/1 FontAwesome, "Rubik", sans-serif;
}

/* Contact Forms */
#contactForm .form-control {
  color: #444;
  background: #f7f7f7;
  font-weight: 400;
  letter-spacing: 0px;
  border: 1px solid #ddd;
  border-radius: 0;
  margin-bottom: 30px;
 
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#contactForm .form-control:focus {
  border-color: #EB586F;
}

#contactForm textarea {
  max-width: 100%;
  min-height: 130px !important;
  max-height: 211px;
  margin-bottom: 30px;
}

::-webkit-input-placeholder {
  color: #ccc !important;
}

:-moz-placeholder {
  color: #ccc !important;
}

::-moz-placeholder {
  color: #ccc !important;
}

:-ms-input-placeholder {
  color: #ccc !important;
}

.has-error .form-control {
  color: #ec7160 !important;
  border: 1px solid #ec7160 !important;
}

.help-block.with-errors li {
  color: #ec7160;
  font-size: 13px;
  margin-top: -30px;
  margin-bottom: 0;
}

#msgSubmit.h3 {
  font-size: 14px;
  margin-top: 5px;
}

/* Contact Form 1 */
#contact-form-1 .form-group {
  margin-bottom: 0;
}

#contact-form-1 #contactForm {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#contact-form-1 .btn-form-submit {
  width: 100%;
  border-radius: 0;
  padding: 14px 24px;
 }

/* Contact Form 2 */
.contact-form-2 {
  margin-bottom: 50px;
}

#contactForm.contact-form-2 .form-control#name {
  width: 70%;
}

#contactForm.contact-form-2 .form-control#email {
  width: 70%;
}

#contactForm.contact-form-2 .form-control#subject {
  width: 70%;
}

#contact-form-2 .form-group {
  margin-bottom: 0;
}

#contact-form-2 #contactForm {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#contact-form-2 .btn-form-submit {
  width: 30%;
  border-radius: 0;
  padding: 14px 24px;
}
.btn{
  background-color: #5a3c8b;
  color:white;
}
.btn:hover{
  background-color: #417ee2;
 
}
.team{
  text-align:left;
  padding-top: 1em;
}
.team img{
  width: 50%;
}
h5{
  margin-top: 2em;
}
.positions{
  font-style: italic;
  font-size: 0.7em;
 
}

#counter {
  background-image: url(./assets/bg.webp);
  background-size: cover;
  
  margin-top: 2em;
}

.count-one {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  
}

.count-one .icon {
  margin-bottom: 20px;
  display: block;
}

.count-one .icon i {
  color: #ffffff;
  font-size: 48px;
}

.count-one .count-value {
  color: #fff;
  font-size: 30px;
  margin-bottom: 10px;
}

.count-one .count-title {
  color: #fff;
  font-size: 1.5em;
}

.width25-divider {
  margin: 20px auto;
  width: 25px;
  border-color: #fff;
}
.download-button{
  text-decoration: none;
  color: #0f032a!important;
 
 
}
.download-button:hover{
  text-decoration: none;
  color: #636060!important;
  
 
}


/* Media Queries */

@media (max-width: 768px) {
  .slick-arrow {
    width: 1rem;
    height: 1rem;
  }
}


.row {
  overflow: hidden;
}  

/* JsFiddle Example only/don't use */

#footer ul li{
  display: inline;
  padding-left: 0.6em;
  
  
}
.award{
  width: 60%;
  align-self: center;
}
.footer-item:hover{
 color:black!important;
  
}
.footer-item{
  color: #ccc!important;
  text-decoration: none;

}
.company{
    display: none;
}
.company1{
  text-align: left;
  font-size: 0.9em;
  font-weight: 600;
  text-transform: uppercase;
}

.base-contact{
 
  text-align: center;
}
.base-contact h1{
  font-size: 2em;
  text-transform: uppercase;
}
.inqbtn{
  color: white!important;

}
@media screen and (max-width: 400px) {
  h1{
    font-size: 1.2em;
    text-align: center;
  }
  #home_tab .col-lg-6{
   
    margin-bottom: 3px;
    margin-top: 3px;
   }
   #fav{
     text-align: center;
   }
   #home_tab h1{
   
    font-size: 1em;
  }
  .nav-item, .nav-link{
    padding-top: 0.3em;
  }
   .carousel-item img{
    height: 200px!important;
  }
  .count-one {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  #footer ul li{
    display:list-item;
    padding-left: 0em;
    text-align: left;
    
    
  }
  .company{
    
    text-align: center;
    font-size: 1em;
    font-weight: 900;
    text-transform: uppercase;
    display: block;
    
  }
  .c1{
    display: none;
  }
  ul li h5{
    font-weight: 600;
    font-size: 1em;
   
    }
    ul li {
     
      margin-left:-1.8em;
      }
    .separator{
      display: none;
    }
    .base-contact h1{
      font-size: 1em;
  font-weight: 600;

    }
}
#tab tr{
  padding:2px;
  border:1px solid black;
}
#tab{
  margin-top: 1em;
  margin-bottom: 1em;
}
#tab td{
  padding:0.5em;
}